var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "find-stroke-tarin-container" }, [
    _c(
      "div",
      {
        staticClass: "find-stroke-tarin-content position-relative",
        class: "game-" + _vm.gameIndex,
      },
      [
        _c("PageButton", {
          attrs: {
            bottomCornerButtonList: _vm.bottomCornerButtonList,
            isLastStep: _vm.isLastStep,
            numBers: _vm.currentStep,
          },
          on: { clickNext: _vm.nextPage },
        }),
        _c("div", { staticClass: "right-gules" }, [
          _c(
            "div",
            { staticClass: "pinyin font-pinyin-medium stroke-pinyin" },
            [_vm._v(" " + _vm._s(_vm.strokePinyin) + " ")]
          ),
          _c("div", { staticClass: "target-stroke-img" }, [
            _c("img", { attrs: { src: _vm.targetStrokeImg, alt: "" } }),
          ]),
        ]),
        _c("div", { ref: "trainBox", staticClass: "header-img-box" }, [
          _c("div", { staticClass: "header-img-content" }, [
            _c("img", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.currentStep === 0,
                  expression: "currentStep === 0",
                },
              ],
              attrs: { src: _vm.headerBgImg.defaultImg, alt: "" },
            }),
            _c("img", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    _vm.currentStep >= _vm.lightIndex[0] &&
                    _vm.currentStep < _vm.lightIndex[1],
                  expression:
                    "currentStep >= lightIndex[0] && currentStep < lightIndex[1]",
                },
              ],
              attrs: { src: _vm.headerBgImg.firstImg, alt: "" },
            }),
            _c("img", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    _vm.currentStep >= _vm.lightIndex[1] &&
                    _vm.currentStep < _vm.lightIndex[2],
                  expression:
                    "currentStep >= lightIndex[1] && currentStep < lightIndex[2]",
                },
              ],
              attrs: { src: _vm.headerBgImg.secondImg, alt: "" },
            }),
            _c("img", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.currentStep === _vm.lightIndex[2],
                  expression: "currentStep === lightIndex[2]",
                },
              ],
              attrs: { src: _vm.headerBgImg.thirdImg, alt: "" },
            }),
            _c(
              "div",
              { ref: "strokeTarinContent", staticClass: "hanzi-tarin-content" },
              _vm._l(_vm.hanziStrokeInfo, function (item, index) {
                return _c(
                  "div",
                  {
                    key: index + "hanziStroke",
                    staticClass: "typeface-picture",
                  },
                  [
                    _c("span", { staticClass: "pinyin font-pinyin-medium" }, [
                      _vm._v(_vm._s(item.pinyin)),
                    ]),
                    _c("div", { staticClass: "block" }, [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/img/11-Syllabus/square.svg"),
                          alt: "",
                        },
                      }),
                      _c(
                        "div",
                        { staticClass: "stroke-block" },
                        [
                          _vm._l(
                            item.strokeFinishImgList,
                            function (ele, indexChild) {
                              return _c("div", {
                                key: indexChild + "child",
                                ref: "stroke_" + ele.index,
                                refInFor: true,
                                staticClass: "stroke",
                                class: "stroke" + ele.index,
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.clickedStroke(
                                      ele.index,
                                      index,
                                      indexChild
                                    )
                                  },
                                },
                              })
                            }
                          ),
                          _c(
                            "div",
                            { staticClass: "red-one" },
                            [
                              _vm._l(
                                item.strokeFinishImgList,
                                function (ele, indexChild) {
                                  return [
                                    ele.isShow === true
                                      ? _c("img", {
                                          key: indexChild + "son",
                                          staticClass: "heng-one",
                                          attrs: { src: ele.bgImg, alt: "" },
                                        })
                                      : _vm._e(),
                                  ]
                                }
                              ),
                            ],
                            2
                          ),
                          _c("img", {
                            attrs: { src: item.strokeBgImg, alt: "" },
                          }),
                        ],
                        2
                      ),
                    ]),
                    index === 0 && _vm.instructionsDisplay
                      ? _c("div", { staticClass: "instructions" }, [
                          _c("img", {
                            staticClass: "blinkAndDisappear",
                            attrs: {
                              src: require("@/assets/img/17-Tutorials/hand.svg"),
                              alt: "",
                            },
                          }),
                        ])
                      : _vm._e(),
                    index !== 0
                      ? _c(
                          "div",
                          {
                            staticClass: "cloud",
                            class: { fadeout: _vm.currentCloudIndex >= index },
                          },
                          [
                            _c("img", {
                              attrs: { src: _vm.shadowImg, alt: "" },
                            }),
                          ]
                        )
                      : _vm._e(),
                  ]
                )
              }),
              0
            ),
          ]),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }