<template>
  <div class="find-stroke-tarin-container">
    <div
      class="find-stroke-tarin-content position-relative"
      :class="'game-' + gameIndex"
    >
      <PageButton
        :bottomCornerButtonList="bottomCornerButtonList"
        :isLastStep="isLastStep"
        :numBers="currentStep"
        @clickNext="nextPage"
      ></PageButton>
      <!-- 红框笔画 -->
      <div class="right-gules">
        <div class="pinyin font-pinyin-medium stroke-pinyin">
          {{ strokePinyin }}
        </div>
        <div class="target-stroke-img" >
          <img :src="targetStrokeImg" alt="" />
        </div>
      </div>

      <div class="header-img-box" ref="trainBox">
        <!-- 头部灯背景 -->
        <div class="header-img-content">
          <img
            v-show="currentStep === 0"
            :src="headerBgImg.defaultImg"
            alt=""
          />
          <!-- </template> -->
          <img
            v-show="currentStep >= lightIndex[0] && currentStep < lightIndex[1]"
            :src="headerBgImg.firstImg"
            alt=""
          />
          <img
            v-show="currentStep >= lightIndex[1] && currentStep < lightIndex[2]"
            :src="headerBgImg.secondImg"
            alt=""
          />
          <img
            v-show="currentStep === lightIndex[2]"
            :src="headerBgImg.thirdImg"
            alt=""
          />
          <!-- 笔顺部分 -->
          <div class="hanzi-tarin-content" ref="strokeTarinContent">
            <div
              class="typeface-picture"
              v-for="(item, index) in hanziStrokeInfo"
              :key="index + 'hanziStroke'"
            >
              <span class="pinyin font-pinyin-medium">{{ item.pinyin }}</span>
              <div class="block">
                <img
                  src="@/assets/img/11-Syllabus/square.svg"
                  alt=""
                />

                <div class="stroke-block">
                  <!-- 占位图 -->
                  <div
                    v-for="(ele, indexChild) in item.strokeFinishImgList"
                    :key="indexChild + 'child'"
                    class="stroke"
                    :ref="'stroke_' + ele.index"
                    :class="'stroke' + ele.index"
                    @click.stop="clickedStroke(ele.index, index, indexChild)"
                  />

                  <!-- 每一笔 -->
                  <div class="red-one">
                    <template
                      v-for="(ele, indexChild) in item.strokeFinishImgList"
                    >
                      <img
                        :key="indexChild + 'son'"
                        class="heng-one"
                        v-if="ele.isShow === true"
                        :src="ele.bgImg"
                        alt=""
                      />
                    </template>
                  </div>

                  <!-- 灰底字 -->
                  <img :src="item.strokeBgImg" alt="" />
                </div>
              </div>
              <!-- 遮罩图片 -->
              <div
                class="instructions"
                v-if="index === 0 && instructionsDisplay"
              >
                <img
                  class="blinkAndDisappear"
                  src="@/assets/img/17-Tutorials/hand.svg"
                  alt=""
                />
              </div>
              <div
                v-if="index !== 0"
                class="cloud"
                :class="{ fadeout: currentCloudIndex >= index }"
              >
                <img :src="shadowImg" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { startConfetti, playCorrectSound } from "@/utils/tools";
import PageButton from "@/components/Course/Interaction/Button/pageButton.vue";

export default {
  components: {
    PageButton,
  },
  props: {
    shadowImg: {
      type: String,
      require: true,
    },
    targetStrokeImg: {
      type: String,
      require: true,
    },
    gameIndex: {
      type: Number,
      require: true,
    },
    hanziStrokeInfo: {
      type: Array,
      require: true,
    },
    lightIndex: {
      type: Array,
      require: true,
    },
    strokePinyin: {
      type: String,
      require: true,
    },
    strokeHanzi: {
      type: String,
      require: false
    },
    headerBgImg: {
      type: Object,
      require: true,
    },
  },
  data() {
    return {
      scrollDom: null,
      distance: 0,
      currentStep: 0,
      currentLigthIndex: 0,
      instructionsDisplay: true,
      currentCloudIndex: 0,
      strokeTrainInfo: {},
      success_audio_sound: require("@/assets/audio/correct_answer.mp3"),
      isLastStep: false,
      clickedStrokeValue: 0,
      bottomCornerButtonList: {
        settings: {
          showNextOnLastStep: true,
        },
        buttons: [
          {
            type: "look",
            startNumberRange: 0,
            endNumberRange: 0,
          },
        ],
      },
    };
  },
  mounted() {
    this.$bus.$on(
      "clickRightStrokeArea",
      ({ strokeIndex, hanziIndex, singleStrokeIndex }) => {
        this.clickedStroke(strokeIndex, hanziIndex, singleStrokeIndex, 1);
      }
    );
    this.getScrollWidth();
  },
  beforeDestroy() {
    this.$bus.$off("clickRightStrokeArea");
  },
  watch: {
    clickedStrokeValue: {
      handler(value) {
        const data = {
          clickType: 20013,
          data: { value: this.strokeTrainInfo },
          text: "FindStrokeTrainGame点击socket",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },
  },
  methods: {
    getScrollWidth() {
      this.$nextTick(() => {
        this.scrollDom = this.$refs.trainBox;
      });
    },
    clickedStroke(strokeIndex, hanziIndex, singleStrokeIndex, type) {
      if (type != 1) {
        this.clickedStrokeValue++;
      }
      let ref = "stroke_" + strokeIndex;
      this.currentStep++;
      if (this.lightIndex.includes(this.currentStep)) {
        if (this.currentStep === 1) {
          this.instructionsDisplay = false;
        }
        this.trainScroll(hanziIndex);
      }
      this.$refs[ref][0].style.pointerEvents = "none";
      this.$emit("showStroke", hanziIndex, singleStrokeIndex);
      this.strokeTrainInfo = { strokeIndex, hanziIndex, singleStrokeIndex };
    },
    // 跳转下一页
    nextPage() {
      this.$bus.$emit("nextButton", true);
    },
    // 自动移动
    trainScroll(index) {
      if (index === 0) {
        playCorrectSound(true, false);
        const timer = setInterval(() => {
          this.distance += 2;
          this.scrollDom.scrollLeft = this.distance;
          if (
            this.scrollDom.scrollLeft + 1 >=
            (this.scrollDom.scrollWidth - this.scrollDom.clientWidth) / 2
          ) {
            clearInterval(timer);
            this.currentCloudIndex++;
          }
        }, 10);
      } else if (index === 1) {
        playCorrectSound(true, false);
        this.$nextTick(() => {
          const timer = setInterval(() => {
            this.distance += 2;
            this.scrollDom.scrollLeft = this.distance;
            if (
              this.scrollDom.scrollLeft >=
              this.scrollDom.scrollWidth - this.scrollDom.clientWidth - 1
            ) {
              clearInterval(timer);
              this.currentCloudIndex++;
            }
          }, 10);
        });
      } else {
        playCorrectSound();
        startConfetti();
        this.isLastStep = true;
      }
    },
  },
};
</script>
<style scoped lang="scss">
.stroke {
  // background-color: black;
  position: absolute;
  z-index: 5;
  cursor: pointer;
}
.game-1 {
  .stroke1 {
    // background-color: black;
    height: 20%;
    width: 64%;
    left: 14%;
    top: 33%;
  }
  .stroke2 {
    // background-color: black;
    height: 12%;
    width: 39%;
    left: 34%;
    top: 36%;
  }
  .stroke3 {
    // background-color: black;
    height: 11%;
    width: 43%;
    left: 31%;
    top: 55%;
  }
  .stroke4 {
    // background-color: black;
    height: 15%;
    width: 79%;
    left: 10%;
    bottom: 11%;
  }
  .stroke5 {
    // background-color: black;
    height: 7%;
    width: 54%;
    left: 23%;
    top: 17%;
  }
  .stroke6 {
    // background-color: black;
    height: 6%;
    width: 17%;
    left: 41%;
    top: 29%;
  }
  .stroke7 {
    //background-color: black;
    height: 6%;
    width: 17%;
    left: 41%;
    top: 41%;
  }
  .stroke8 {
    //background-color: black;
    height: 8%;
    width: 80%;
    left: 10%;
    bottom: 39%;
  }
}
.game-2 {
  .stroke1 {
    // background-color: black;
    height: 24%;
    width: 57%;
    left: 30%;
    top: 30%;
  }
  .stroke2 {
    // background-color: black;
    height: 35%;
    width: 31%;
    left: 37%;
    top: 20%;
  }
  .stroke3 {
    // background-color: black;
    height: 25%;
    width: 25%;
    left: 43%;
    top: 19%;
  }
  .stroke4 {
    // background-color: black;
    height: 26%;
    width: 14%;
    left: 20%;
    bottom: 53%;
  }
}
.game-3 {
  .stroke1 {
    // background-color: black;
    height: 64%;
    width: 10%;
    left: 29%;
    top: 22%;
    transform: rotate(27deg);
  }
  .stroke2 {
    // background-color: black;
    height: 29%;
    width: 10%;
    left: 25%;
    top: 12%;
    transform: rotate(49deg);
  }
  .stroke3 {
    // background-color: red;
    height: 41%;
    width: 7%;
    left: 25%;
    top: 29%;
    transform: rotate(49deg);
  }
  .stroke4 {
    // background-color: black;
    height: 34%;
    width: 10%;
    left: 24%;
    top: 10%;
    transform: rotate(46deg);
  }
  .stroke5 {
    // background-color: black;
    height: 34%;
    width: 10%;
    left: 24%;
    top: 30%;
    transform: rotate(46deg);
  }
  .stroke6 {
    // background-color: black;
    height: 40%;
    width: 10%;
    left: 24%;
    top: 50%;
    transform: rotate(46deg);
  }
  .stroke7 {
    // background-color: black;
    height: 17%;
    width: 8%;
    left: 56%;
    top: 19%;
    transform: rotate(41deg);
  }
  .stroke8 {
    // background-color: black;
    height: 48%;
    width: 8%;
    left: 52%;
    top: 43%;
    transform: rotate(27deg);
  }
}
.game-4 {
  .stroke1 {
    // background-color: black;
    height: 15%;
    width: 39%;
    left: 43%;
    top: 59%;
    transform: rotate(44deg);
  }
  // .stroke2 {
  //   // background-color: black;
  //   height: 12%;
  //   width: 21%;
  //   left: 53%;
  //   top: 13%;
  //   transform: rotate(44deg);
  // }
  .stroke2 {
    // background-color: red;
    height: 11%;
    width: 51%;
    left: 34%;
    top: 35%;
    transform: rotate(29deg);
  }
  .stroke3 {
    // background-color: black;
    height: 12%;
    width: 38%;
    left: 47%;
    top: 60%;
    transform: rotate(29deg);
  }
}
.game-5 {
  .stroke1 {
    height: 10%;
    width: 18%;
    left: 42%;
    top: 10%;
    transform: rotate(45deg);
  }
  .stroke2 {
    height: 9%;
    width: 18%;
    left: 27%;
    top: 20%;
    transform: rotate(41deg);
  }
  .stroke3 {
    height: 9%;
    width: 18%;
    left: 20%;
    top: 35%;
    transform: rotate(41deg);
  }
  .stroke4 {
    height: 13%;
    width: 34%;
    left: 54%;
    bottom: 19%;
    transform: rotate(45deg);
  }
  .stroke5 {
    height: 9%;
    width: 22%;
    left: 5%;
    top: 78%;
    transform: rotate(116deg);
  }
  .stroke6 {
    height: 8%;
    width: 17%;
    left: 30%;
    top: 74%;
    transform: rotate(55deg);
  }
  .stroke7 {
    height: 8%;
    width: 17%;
    left: 50%;
    top: 72%;
    transform: rotate(55deg);
  }
  .stroke8 {
    height: 10%;
    width: 23%;
    left: 71%;
    top: 72%;
    transform: rotate(55deg);
  }
}
.game-6 {
  .stroke1 {
    height: 69%;
    width: 18%;
    left: 33%;
    top: 14%;
    transform: rotate(29deg);
  }
  .stroke2 {
    height: 49%;
    width: 10%;
    left: 47%;
    top: 7%;
    transform: rotate(51deg);
  }
  .stroke3 {
    height: 56%;
    width: 12%;
    left: 51%;
    top: 39%;
    transform: rotate(51deg);
  }
  .stroke4 {
    height: 14%;
    width: 20%;
    left: 41%;
    top: 9%;
    transform: rotate(141deg);
  }
  .stroke5 {
    height: 14%;
    width: 20%;
    left: 24%;
    top: 33%;
    transform: rotate(141deg);
  }
  .stroke6 {
    height: 11%;
    width: 17%;
    left: 52%;
    top: 29%;
    transform: rotate(141deg);
  }
}

.game-7 {
  .stroke1 {
    height: 63%;
    width: 26%;
    left: 65%;
    top: 24%;
  }
  .stroke2 {
    height: 53%;
    width: 25%;
    left: 47%;
    top: 29%;
  }
  .stroke3 {
    height: 60%;
    width: 13%;
    left: 35%;
    top: 18%;
  }
  .stroke4 {
    height: 66%;
    width: 14%;
    left: 70%;
    top: 16%;
  }
}
.game-8 {
  .stroke1 {
    height: 75%;
    width: 26%;
    left:25%;
    top: 15%;
  }
  .stroke2 {
    height: 70%;
    width: 30%;
    left: 47%;
    top: 10%;

  }
  .stroke3 {
    height: 50%;
    width: 12%;
    left: 35%;
    top: 18%;
  }
  .stroke4 {
    height: 50%;
    width: 12%;
    left: 50%;
    top: 16%;
  }
  .stroke5 {
    height: 50%;
    width: 12%;
    left: 66%;
    top: 16%;
  }
}
.game-9 {
  .stroke1 {
    height: 68%;
    width: 18%;
    left:40%;
    top: 15%;
    .item1{

    }
  }
  .stroke2 {
    height: 56%;
    width: 20%;
    left: 40%;
    top: 24%;

  }
  .stroke3 {
    height: 70%;
    width: 18%;
    left: 48%;
    top: 15%;
  }

}
.game-10 {
  .stroke1 {
    height: 38%;
    width: 32%;
    left:35%;
    top: 48%;

  }
  .stroke2 {
    height: 33%;
    width: 16%;
    left: 20%;
    top: 14%;

  }
  .stroke3 {
    height: 36%;
    width: 20%;
    left: 26%;
    top: 30%;

  }
  .stroke4 {
    height: 30%;
    width: 18%;
    left: 26%;
    top: 15%;

  }
  .stroke5 {
    height: 28%;
    width: 18%;
    left: 30%;
    top: 38%;
  
  }
  .stroke6 {
    height: 30%;
    width: 18%;
    left: 52%;
    top: 15%;

  }
  .stroke7 {
    height: 28%;
    width: 18%;
    left: 56%;
    top: 38%;
  }

}
.game-11 {
  .stroke1 {
    height: 24%;
    width: 57%;
    left:25%;
    top: 53%;
  }
  .stroke2 {
    height: 64%;
    width: 20%;
    left: 23%;
    top: 17%;
  }
  .stroke3 {
    height: 20%;
    width: 50%;
    left: 24%;
    top: 34%;
  }
  .stroke4 {
    height: 20%;
    width: 50%;
    left: 24%;
    top: 62%;
  }
  .stroke5 {
    height: 50%;
    width: 12%;
    left: 66%;
    top: 16%;
  }
}
.game-11 {
  .stroke1 {
    height: 24%;
    width: 57%;
    left:25%;
    top: 53%;
  }
  .stroke2 {
    height: 64%;
    width: 20%;
    left: 23%;
    top: 17%;
  }
  .stroke3 {
    height: 20%;
    width: 50%;
    left: 24%;
    top: 34%;
  }
  .stroke4 {
    height: 20%;
    width: 50%;
    left: 24%;
    top: 62%;
  }
  .stroke5 {
    height: 50%;
    width: 12%;
    left: 66%;
    top: 16%;
  }
}
.game-26 {
  .stroke1 {
    height: 30%;
    width: 20%;
    left:20%;
    top: 40%;
  }
  .stroke2 {
     height: 62%;
    width: 32%;
    left:46%;
    top: 18%;
  }
  .stroke3 {
     height: 60%;
    width: 26%;
    left:15%;
    top: 23%;
  }
}
.game-28 {
  .stroke1 {
    height: 50%;
    width: 30%;
    left:42%;
    top: 42%;
  }
  .stroke2 {
    height: 48%;
    width: 30%;
    left:39%;
    top: 37%;
  }
  .stroke3 {
     height: 65%;
    width: 35%;
    left:45%;
    top: 23%;
  }
}
.game-30 {
  .stroke1 {
    height: 34%;
    width: 23%;
    left:24%;
    top: 20%;
  }
  .stroke2 {
    height: 48%;
    width: 30%;
    left:59%;
    top: 20%;
  }
  .stroke3 {
    height: 32%;
    width: 23%;
    left:24%;
    top: 15%;
  }
}
.game-34 {
  .stroke1 {
    height: 27%;
    width: 32%;
    left:39%;
    top: 10%;
  }
  .stroke2 {
     height: 27%;
    width: 28%;
    left:50%;
    top: 13%;
  }
  .stroke3 {
     height: 29%;
    width: 28%;
    left:57%;
    top: 17%;
  }
}
.game-36 {
  .stroke1 {
    height: 45%;
    width: 13%;
    left:34%;
    top: 42%;
   
  }
  .stroke2 {
    height: 45%;
    width: 13%;
    left:23%;
    top: 25%;
  }
  .stroke3 {
    height: 42%;
    width: 13%;
    left:27%;
    top: 41%;
  }
  .stroke4 {
    height: 60%;
    width: 12%;
    left:48%;
    top: 24%;
  }
}
.game-38 {
  .stroke1 {
    height: 23%;
    width: 33%;
    left:14%;
    top: 42%;
  }
  .stroke2 {
    height: 36%;
    width: 18%;
    left:13%;
    top: 40%;
  }
  .stroke3 {
    height: 22%;
    width: 22%;
    left:14%;
    top: 46%;
  }
  .stroke4 {
    height: 21%;
    width: 22%;
    left:48%;
    top: 42%;
  }
}
.game-42 {
  .stroke1 {
    height: 15%;
    width: 33%;
    left:50%;
    top: 26%;
  }
  .stroke2 {
    height: 15%;
    width: 50%;
    left:30%;
    top: 13%;
  }
  .stroke3 {
    height: 15%;
    width: 43%;
    left:27%;
    top: 11%;
  }
 
}
.game-44 {
  .stroke1 {
    height: 70%;
    width: 14%;
    left:42%;
    top: 12%;
  }
  .stroke2 {
    height: 78%;
    width: 14%;
    left:60%;
    top: 7%;
  }
  .stroke3 {
    height: 78%;
    width: 14%;
    left:30%;
    top: 5%;
  }
  .stroke4 {
    height: 60%;
    width: 14%;
    left: 60%;
    top: 27%;
  }
}
.game-46 {
  .stroke1 {
    height: 70%;
    width: 14%;
    left:42%;
    top: 20%;
  }
  .stroke2 {
    height: 58%;
    width: 14%;
    left:58%;
    top: 28%;
  }
  .stroke3 {
    height: 65%;
    width: 16%;
    left:21%;
    top: 18%;
  }
}
.game-50 {
  .stroke1 {
    height: 25%;
    width: 44%;
    left:32%;
    top: 48%;
  }
  .stroke2 {
    height: 22%;
    width: 38%;
    left:45%;
    top: 55%;
  }
  .stroke3 {
    height: 18%;
    width: 25%;
    left:40%;
    top: 38%;
  }
   .stroke4 {
    height: 18%;
    width: 25%;
    left:22%;
    top: 68%;
  }
   .stroke5 {
    height: 18%;
    width: 25%;
    left:56%;
    top: 68%;
  }
}
.game-52 {
  .stroke1 {
    height: 18%;
    width: 80%;
    left:20%;
    top: 48%;
    transform: rotate(55deg)
  }
  .stroke2 {
    height: 20%;
    width: 80%;
    left:25%;
    top: 40%;
    transform: rotate(60deg)
  }
  .stroke3 {
    height: 20%;
    width: 83%;
    left:24%;
    top: 38%;
    transform: rotate(60deg)
  }
}
.game-54 {
  .stroke1 {
    height: 40%;
    width: 23%;
    left:17%;
    top: 40%;
  }
  .stroke2 {
    height: 40%;
    width: 22%;
    left:18%;
    top: 40%;
  }
  .stroke3 {
    height: 40%;
    width: 22%;
    left:18%;
    top: 40%;
  }
}
.game-58 {
  .stroke1 {
    height: 26%;
    width: 21%;
    left:54%;
    top: 30%;
  }
  .stroke2 {
    height: 26%;
    width: 14%;
    left:56%;
    top: 24%;
  }
  .stroke3 {
    height: 18%;
    width: 13%;
    left:51%;
    top: 23%;
  }
}
.game-60 {
  .stroke1 {
    height: 60%;
    width: 21%;
    left:50%;
    top: 20%;
  }
  .stroke2 {
    height: 38%;
    width: 40%;
    left:44%;
    top: 46%;
  }
  .stroke3 {
    height: 34%;
    width: 40%;
    left:44%;
    top: 50%;
  }
}
.game-62 {
  .stroke1 {
    height: 60%;
    width: 23%;
    left:54%;
    top: 20%;
  }
  .stroke2 {
    height: 58%;
    width: 22%;
    left:60%;
    top: 26%;
  }
  .stroke3 {
    height: 38%;
    width: 30%;
    left:36%;
    top: 50%;
  }
}
.find-stroke-tarin-container {
  width: 100%;
  height: 100%;
  position: relative;
  .find-stroke-tarin-content {
    width: 100%;
    height: 100%;
    background-image: url("../../../../assets/img/03-Backgrounds/background-practice.svg");
    border-radius: 58px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    position: relative;

    .header-img-box {
      width: 100%;
      height: 100%;
      overflow-x: scroll;
      white-space: nowrap;
      border-radius: 58px;
      position: relative;
      .header-img-content {
        height: 100%;
        position: absolute;
        // top: 5vh;
        img {
          display: inline-block;
          height: 35%;
        }
        .hanzi-tarin-content {
          // position: absolute;
          // bottom: 0;
          height: 63%;
          display: flex;
          align-items: center;
          .typeface-picture {
            width: 16%;
            height: 75%;
            margin-left: 14%;
            background-color: #fff;
            border-radius: 30px;
            position: relative;
            border-right: 10px solid #2258a7;
            border-bottom: 10px solid #2258a7;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-flow: column;
            font-family: "STHeitiSCPinyin-Light";
            .block {
              width: 80%;
              height: 60%;
              display: flex;
              justify-content: center;
              align-items: center;
              position: relative;
              img {
                height: 100%;
              }
              .stroke-block {
                width: 100%;
                height: 100%;
                position: absolute;
                left: 0%;
                display: flex;
                justify-content: center;
                align-items: center;
                img {
                  // height: 100%;
                  width: 100%;
                  // cursor: pointer;
                }
                .red-one {
                  width: 100%;
                  height: 100%;
                  position: absolute;
                  top: 0%;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  .heng-one {
                    position: absolute;
                    // left:0%;
                  }
                  .heng-two {
                    position: absolute;
                    // left:0%;
                  }
                  .heng-three {
                    position: absolute;
                    // left:0%;
                  }
                }
              }
            }
            span {
              color: #cd4c3f;
              margin-top: 2%;
            }
            .cloud {
              width: 100%;
              position: absolute;
              bottom: 15%;
              left: -10%;
              pointer-events: none;
              img {
                width: 120%;
              }
            }
            .instructions {
              width: 100%;
              position: absolute;
              bottom: 15%;
              left: 50%;
              //  cursor: pointer;
              img {
                width: 50%;
              }
            }
          }
        }
      }
    }
    ::-webkit-scrollbar {
      display: none; /* Chrome Safari */
    }
  }
}
.right-gules {
  width: 18%;
  height: 15%;
  background-color: #cd4c3f;
  position: absolute;
  right: 0;
  top: 5%;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  z-index: 6;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .stroke-pinyin {
    flex: 3;
    display: flex;
    align-items: center;
    color: #fff;
  }

  .target-stroke-img {
    flex: 2;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 10px;
    img {
      width: 50%;
      color: #fff;
    }
  }
}
.py-font-size {
  font-size: 1.5em;
}
@keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: scale(2);
  }
}

.fadeout {
  color: white;
  -webkit-animation: fadeout 0.5s ease-in forwards;
  -moz-animation: fadeout 0.5s ease-in forwards;
  animation: fadeout 0.5s ease-in forwards;
}
</style>
